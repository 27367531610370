<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>TESTIMONIAL</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.testimonialAddModal.show()" text="Add"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3">
                <validated-input label="Search" v-model="ext_search"/>
            </div>
        </div>

        <custom-vue-table :show-search-box="false" :fields="fields" :url="listUrl"
                          class="c-cms-table d-sm-none-lg-block"
                          :extra-params="{ext_search}" :per-page="10" ref="table">

            <template #testimonial_user_image="{rowData}">
                <div class="container-image fl-x-cc" @click="viewFile(rowData.testimonial_user_image)">
                    <img :src="rowData.testimonial_user_image" alt="Avatar" class="c-image cursor-pointer">
                    <div class="c-overlay">
                        <div class="c-icon ml-3">
                            <i class="fa fa-eye cursor-pointer"></i>
                        </div>
                    </div>
                </div>
            </template>

            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>

        </custom-vue-table>
        <testimonial-mobile-view :ext_search="ext_search" ref="testimonialMobileView" class="d-lg-none"
                                 @viewDetails="viewDetails" @setDelete="setDelete"></testimonial-mobile-view>

        <modal title="Add Testimonial" ref="testimonialAddModal" width="60r" :no-close-on-backdrop="true"
               header-color="primary">
            <AddTestimonial @success="formSuccess"></AddTestimonial>
        </modal>

        <modal title="Edit Testimonial" ref="testimonialEditModal" :no-close-on-backdrop="true" width="60r"
               header-color="primary">
            <EditTestimonial :initial-data="editingItem" @success="formSuccess"></EditTestimonial>
        </modal>

        <delete-modal ref="testimonialDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Testimonial <b v-html="deletingItem && deletingItem.testimonial_user"></b>.
                Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.testimonial_user"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddTestimonial from './AddTestimonial';
import EditTestimonial from './EditTestimonial';
import TestimonialMobileView from '../../../../views/cms/home/testimonial/TestimonialMobileView';

export default {
    name : 'Testimonial',

    components : { TestimonialMobileView, AddTestimonial, EditTestimonial },

    data () {
        return {
            ext_search : '',
            listUrl    : urls.cms.testimonial.list,
            deleteUrl  : urls.cms.testimonial.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'order',
                    sortField : 'order',
                    title     : 'Order'
                },
                {
                    name  : '__slot:testimonial_user_image',
                    title : 'Image'
                },
                {
                    name      : 'testimonial_user',
                    sortField : '',
                    title     : 'Testimonial User'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/cms/testimonial/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.testimonialAddModal.close();
            refs.testimonialEditModal.close();
            refs.testimonialMobileView.loadData();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.testimonialEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.testimonialDeleteModal.show();
        },

        deleteComplete (response) {
            this.$refs.testimonialMobileView.loadData();

            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted!', 'Success', { type : 'success' }
                );
            }

            this.deletingItem = null;
            this.$refs.testimonialDeleteModal.close();
            this.$refs.table.refreshTable();
        }

    }
};
</script>

<style scoped>

</style>
