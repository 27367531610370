<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">

        <div class="row">
            <div class="col-12">
                <validated-input label="Testimonial User*" v-model="model.testimonial_user"
                                 :rules="{required: true, max:30}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Testimonial Description*" v-model="model.testimonial_description"
                                     :rules="{required: true, max:80}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" label="Testimonial User Image" v-model="model.testimonial_user_image"
                            :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input label="Order" type="number" v-model="model.order"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                     loading-text="Saving..." :disabled="loading"/>
            </div>
        </div>

    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name : 'AddTestimonial',

    data () {
        return {
            addUrl : urls.cms.testimonial.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Testimonial..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
